<template>
  <b-row>
    <b-col cols="12" lg="12">
        <b-card class="w-100 mb-4" no-body>
            <div class="p-35">
                <div class="d-flex align-items-start">
                    <div>
                    <h4 class="card-title mb-1">Sale Order order to add CheckOut</h4>
                    <b-card-text class="text-muted">
                        To use add border-bottom py-3 class in the every row styling.
                    </b-card-text>
                    </div>
                </div>
                </div>
                <hr class="m-0" />
                <b-card-body class="bg-light">
                <!-- part 2 start -->
                <h4 class="card-title">Sale Order Details</h4>
                <b-row class="border-bottom align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="interest"> Salesman </label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-select
                        v-model="form.salesman_id"
                        :options="salesmen"
                    ></b-form-select>
                    </b-col>
                </b-row>
                <b-row class="border-bottom align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="company"> Sales Person</label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-input
                        id="po-number"
                        type="text"
                        placeholder="Sales person name"
                        v-model="form.sales_person"
                    ></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="border-bottom align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="company"> Veh No </label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-input
                        id="purchaseref"
                        type="text"
                        placeholder="Veh No"
                        v-model="form.vehicle"
                    ></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="border-bottom align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="company"> Payment Ref </label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-input
                        id="paymentref"
                        type="text"
                        placeholder="Payment No"
                        v-model="form.payment_ref"
                    ></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="border-bottom align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="company"> Payment Method </label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-input
                        id="paymentmethod"
                        type="text"
                        placeholder="Cash"
                        v-model="form.payment_method"
                    ></b-form-input>
                    </b-col>
                </b-row>
                </b-card-body>
        </b-card>
    </b-col>
    <b-col cols="12" lg="12">
        <b-card class="w-100 mb-4">
        <div class="d-flex">
          <b-col cols="12" lg="3">
            <h6>Name</h6>
            <Select2 
              v-model="defaultItem.product_code" 
              :options="productOptions"
              @change="selectProduct()"
            />
          </b-col>
          <b-col cols="12" lg="3">
            <h6>Batch</h6>
            <b-form-select
                v-model="defaultItem.batch"
                :options="availabilityOptions"
                @change="setAvailableQty()"
            ></b-form-select>
          </b-col>
          <b-col cols="12" lg="3">
            <h6>Quantity</h6>
            <b-form-input
                v-model="defaultItem.initial_quantity"
                placeholder="0"
                name="name-group"
                type="number"
                :max="defaultItem.qty_out"
            ></b-form-input>
          </b-col>
          <b-col cols="12" lg="3">
            <b-button class="mt-4 ml-auto" variant="success" @click="addProduct">+ Add Product</b-button>
          </b-col>
        </div>
        </b-card>
    </b-col>
    <b-col cols="12" lg="12">
        <b-card>
        <b-table
        hover
        bordered
        responsive
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        class="contact-table"
        >
        <!-- A custom formatted column -->
        <template #cell(product_name)="data">
            <div class="d-flex align-items-center">
            <h5 class="mx-2 mb-0">{{ data.item.product_name }}</h5>
            </div>
        </template>
        
        <!-- A custom formatted column -->
        <template #cell(actions)="data">
            <!--<a
            class="mx-1 cursor-pointer text-danger"
            @click="editItem(data.item.id)"
            ><feather type="edit" class="text-info feather-sm"></feather
            ></a>-->
            <a
            v-if="!$route.params.id"
            class="mx-1 cursor-pointer text-danger"
            @click="deleteItem(data.item.id)"
            ><feather type="trash-2" class="text-danger feather-sm"></feather
            ></a> 
        </template>
        </b-table>
        <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        align="center"
        ></b-pagination>
    </b-card>
    </b-col>
    <b-col cols="12" lg="12">
        <b-card class="w-100 mb-4" no-body>
            <div class="p-35">
                <div class="d-flex align-items-start">
                    <div>
                    <h4 class="card-title mb-1">Click save to finalize CheckIn</h4>
                    <b-card-text class="text-muted">
                        You will have 24Hrs to edit this purchase to edit
                    </b-card-text>
                    </div>
                </div>
                </div>
                <hr class="m-0" />
                <b-card-body class="bg-light">
                <!-- part 2 start -->
                <!--<h4 class="card-title">Purchase Order Totals</h4>
                
                <b-row class="border-bottom align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="company"> Net Total </label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-input
                        v-model="form.net_total"
                        id="company"
                        type="text"
                        placeholder="Net Total"
                    ></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="border-bottom align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="company"> Sales Tax </label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-input
                        v-model="form.sales_tax"
                        id="company"
                        type="text"
                        placeholder="Sales Tax"
                    ></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="aboutcomp"> Grand Total </label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-input
                        v-model="form.grand_total"
                        id="aboutcomp"
                        type="text"
                        placeholder="Grand Total"
                    ></b-form-input>
                    </b-col>
                </b-row>-->
                </b-card-body>
                <div class="p-35 border-top">
                <div class="btn-grp text-right">
                    <b-button pill variant="primary" class="px-4" @click="saveCheckOut()">Save</b-button>
                    <!--<b-button pill variant="danger" class="px-4">Cancel</b-button>-->
                </div>
            </div>
        </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
export default {
  name: "CheckOut",
  data: () => ({
    form: {
      salesman_id : null,
      salesman_name : null,
      payment_method : "",
      payment_ref : "",
      checkout_status : 0,
      sales_person : "",
      vehicle : "",
      datetime : moment().format(),
      type : 1
    },
    currentProduct: null,
    availability: [],
    availabilityOptions: [],
    sortBy: "product_code",
    fields: [
      {
        key: "product_code",
        label: "Product SKU",
      },
      {
        key: "product_name",
        label: "Product Name",
      },
      {
        key: "batch",
        label: "Batch #",
      },
      {
        key: "initial_quantity",
        label: "Quantity",
      },
      {
        key: "final_quantity",
        label: "Qty After Returns",
      },
      {
        key: "actions",
        label: "Actions",
      }
    ],
    items: [],
    products: [],
    productOptions: [],
    filter: null,
    totalRows: 1,
    currentPage: 1,
    perPage: 5,
    url: null,
    editedIndex: -1,
    defaultItem:  {
      checkout_id: null,
      product_code: null,
      product_name: null,
      unit_price: 0,
      initial_quantity : 0,
      final_quantity : 0,
      sub_total : 0,
      pdate: null,
      sdate: moment().format(),
      edate: null,
      batch: null,
      qty_out: null,
      checkin_pid: null
    },
    editedItem:  {
      checkout_id: null,
      product_code: null,
      product_name: null,
      unit_price: 0,
      initial_quantity : 0,
      final_quantity : 0,
      sub_total : 0,
      pdate: null,
      sdate: moment().format(),
      edate: null,
      batch: null,
      qty_out: null,
      checkin_pid: null
    },
    salesmen: []
  }),
  created() {
  },
  mounted() {
    if(this.$route.params.id){
      this.loadCheckout(this.$route.params.id);
    }
    this.loadProducts();
    this.loadSalesmen();
  },
  methods: {
    ...mapActions(["getSalesmen","getProducts","getCheckoutProducts","updateCheckinItem","addCheckout","addCheckoutItem","getCheckout","getAvailableProducts","updateInventory"]),
    loadCheckout(id){
      this.getCheckout(id).then(res => {
        this.form = res.data[0];
        console.log(this.form);
        this.loadCheckoutProducts();
      })
    },
    loadProducts(){
      this.getProducts().then((res) => {
        this.products = res.data;
        this.productOptions = res.data.filter((prod) => {
          return prod.product_quantity != 0
        })
        .map(p => {
          return { text:p.product_code+"("+p.product_quantity+")", id:p.product_code }
        });
        // Set the initial number of items
        //this.totalRows = this.items.length;
      })
    },
    loadCheckoutProducts(){
      this.getCheckoutProducts(this.form.checkout_id).then((res) => {
        this.items = res.data;
        // Set the initial number of items
        this.totalRows = this.items.length;
      })
    },
    loadSalesmen(){
      this.getSalesmen().then((res) => {
        this.salesmen = res.data.map(s => {
          return { value:s.salesman_id, text:s.salesman_name }
        });
      })
    },
    addItem() {
      this.$refs["add-modal"].show();
    },
    deleteItem(item) {
      if(this.$route.params.id){
        confirm("Are you sure you want to delete this item?") &&
          console.log("Deleted");
      }
      else{
        const index = this.items.indexOf(item);
        this.items.splice(index, 1);
      }
    },
    selectProduct(){
      this.currentProduct = this.products.find(p => { return p.product_code === this.defaultItem.product_code });
      this.defaultItem.product_name = this.currentProduct.product_name;
      setTimeout(() => {
        this.loadAvailableProducts();
      }, 500)
    },
    loadAvailableProducts(){
      this.getAvailableProducts(this.currentProduct.product_code).then(res => {
        this.availability = res.data.filter(p => { return p.qty-p.qty_out > 0});
        this.sortedAvailablity = _.sortBy(this.availability, ['edate']);
        this.availabilityOptions = this.sortedAvailablity.map(o => { return { text: o.batch+" ("+(o.qty-o.qty_out)+")"+" expiring "+moment(o.edate).fromNow(), value: o.batch} });
      })
    },
    setAvailableQty(){
      let selected = this.availability.find(p => { return p.batch === this.defaultItem.batch });
      this.defaultItem.initial_quantity = selected.qty - selected.qty_out;
      this.defaultItem.pdate = selected.pdate;
      this.defaultItem.edate = selected.edate;
      this.defaultItem.qty_out = selected.qty_out;
      this.defaultItem.checkin_pid = selected.checkin_product_id;
    },
    addProduct() {
      //this.selectProduct();
      this.items.push(this.defaultItem);
      this.defaultItem = {
        checkout_id: null,
        product_code: null,
        product_name: null,
        unit_price: 0,
        initial_quantity : 0,
        final_quantity : 0,
        sub_total : 0,
        pdate: null,
        sdate: moment().format(),
        edate: null,
        batch: null,
        qty_out: 0,
        checkin_pid: null
      }
    },
    saveCheckOut(){
      let sm = this.salesmen.find(s => { return s.value === this.form.salesman_id });
      //console.log(sm);
      this.form.salesman_name = sm.text;
      this.addCheckout(this.form).then(res => {
        let saleid = res.data.id;
        this.items.forEach((item, n) => {
          item.checkout_id = saleid;
          this.addCheckoutItem(item).then(res => {
            console.log(res);
            this.updateCheckinItem({id: item.checkin_pid, qty_out: parseInt(item.qty_out)+parseInt(item.initial_quantity)});
            let prod = this.products.find(p => { return item.product_code === p.product_code });
            this.updateInventory({ id: prod.product_id, qty: -item.initial_quantity }).then(resp => {
              console.log(resp.data.id);
            })
          })
          if(this.items.length === n+1){
            this.$router.push({name: "List CheckOuts"})
          }
        }); 
      })
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Product" : "Edit Product";
    },
    rows() {
      return this.items.length;
    },
  },
  watch: {},
};
</script>

<style>
.contact-table td {
  vertical-align: middle;
}
</style>
